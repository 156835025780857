@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.defaultUserFields {
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.row,
.customFields {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

.continueButton {
  margin-top: 30px;
}

.backButton {
  border: solid 1px #e2e2e2;
  width: fit-content;
  padding: 8px 9px;
  height: fit-content;
  border-radius: 4px;
}

.topicComponent {
  padding: 30px 0px 0px 0px;
}

.nextStepTopic {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #4a4a4a;
  font-family: Inter;
  margin-bottom: 10px;
}

.nextStepDesciption {
  color: #444446;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 200% */
  letter-spacing: -0.089px;
}

.toolTipText{
  display: flex;
  justify-items: center;
}
